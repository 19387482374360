<template>
    <div>
        <div
            v-if="loading"
            class="text-center mt-3"
        >
            {{ $t('app.content_loading') }}
        </div>
        <div v-else>
            <div
                v-for="(period, index) in formattedPeriods"
                :key="index"
            >
                <TableAccordionItem
                    :period="getPeriodDate(period)"
                    :balance="currencyFormatter(period.balance)"
                    :is-active="period.active"
                >
                    <div class="block lg:hidden">
                        <div
                            v-for="(item, index) in period.items"
                            :key="index"
                            class="mt-3"
                        >
                            <TableMobileItem>
                                <template #main>
                                    <div class="flex items-center">
                                        <icon
                                            :name="getIconName(item.status)"
                                            :class="[getStatusColor(item.status), getIconSize(item.status)]"
                                        />
                                        <p class="pt-1 pl-3">
                                            {{ dateFormatter(item.postDate) }}
                                        </p>
                                    </div>
                                    <p>
                                        {{ currencyFormatter(item.amount) }}
                                    </p>
                                </template>
                                <template #additional>
                                    <p
                                        v-if="item.reference"
                                        class="pl-7"
                                    >
                                        #{{ item.reference }}
                                    </p>
                                    <p></p>
                                    <p
                                        v-if="item.note"
                                        class="text-left w-full text-red-600 text-medium text-2xs mt-2.5 font-sofia"
                                    >
                                        {{ item.note }}
                                    </p>
                                </template>
                            </TableMobileItem>
                        </div>
                    </div>

                    <TableDesktop class="hidden lg:block">
                        <template #colgroup>
                            <col width="15%" />
                            <col width="20%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="25%" />
                            <col width="0%" />
                        </template>
                        <template #headers>
                            <th>{{ $t('payments.list_columns.date_posted') }}</th>
                            <th>{{ $t('payments.list_columns.payer') }}</th>
                            <th>{{ $t('payments.list_columns.reference_no') }}</th>
                            <th>{{ $t('payments.list_columns.status') }}</th>
                            <th>{{ $t('payments.list_columns.notes') }}</th>
                            <th>{{ $t('payments.list_columns.amount') }}</th>
                        </template>
                        <template #content>
                            <tr
                                v-for="(item, depositId) in period.items"
                                :key="depositId"
                                class="tbodyTr"
                            >
                                <td data-test="postDate">{{ dateFormatter(item.postDate) }}</td>
                                <td data-test="payer">{{ item.payer }}</td>
                                <td data-test="reference">{{ item.reference }}</td>
                                <td
                                    class="lowercase"
                                    :class="getStatusColor(item.status)"
                                    data-test="status"
                                >
                                    {{ item.status }}
                                </td>
                                <td
                                    :class="getStatusColor(item.status)"
                                    data-test="notes"
                                >
                                    {{ item.notes }}
                                </td>
                                <td data-test="amount">
                                    {{ currencyFormatter(item.amount) }}
                                </td>
                            </tr>
                        </template>
                    </TableDesktop>
                </TableAccordionItem>
            </div>
        </div>
    </div>
</template>

<script>
import TableMobileItem from '@/components/payments/components/TableMobileItem';
import TableDesktop from '@/components/payments/components/TableDesktop';
import TableAccordionItem from '@/components/payments/components/TableAccordionItem';
import Icon from '@/components/ui/Icon.vue';
import PayerMixin from '@/mixins/PayerMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { dateFormatter, getPeriodDate, getFirstAndLastDatesMonth } from '@/utils/Date';
import { currencyFormatter } from '@/utils/helpers';
import { defaultMeta, depositsStatuses } from '@/components/dashboard/constants.js';
import { mapGetters } from 'vuex';
import { getAccountingErrorMessage } from '@/utils/accounting';

export default {
    name: 'PaymentsList',

    components: {
        TableAccordionItem,
        TableMobileItem,
        TableDesktop,
        Icon,
    },

    mixins: [PayerMixin, NotifyMixin, AccountingQueryManager],

    props: {
        /**
         * Avaliable totals for certain period
         * with period/balance information
         */
        totals: {
            type: Array,
            required: true,
        },

        /**
         * Selected filter of a period to get
         * accounts receivable data
         */
        selectedPeriod: {
            type: Object,
            required: true,
        },

        /**
         * If the selected option is 'all'
         * then load more balances
         */
        loadMore: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
            periods: [],
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
        }),
    },

    watch: {
        'currentProfile.occupancyId'(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.getOccupancy();
                this.getDeposits();
            }
        },

        selectedPeriod(val) {
            if (val) {
                this.getDeposits();
            }
        },

        loadMore(val) {
            if (val) {
                this.getDeposits();
            }
        },
    },

    async created() {
        await this.getOccupancy();
        await this.getDeposits();
    },

    methods: {
        dateFormatter,
        getPeriodDate,
        getFirstAndLastDatesMonth,
        currencyFormatter,

        getStatusColor(status) {
            return depositsStatuses.includes(status) ? 'text-purple-600' : 'text-red-600';
        },

        getIconName(status) {
            return depositsStatuses.includes(status) ? 'check-circle' : 'exclamation-circle';
        },

        getIconSize(status) {
            return depositsStatuses.includes(status) ? 'w-4 h-4' : 'w-3.5 h-3.5';
        },

        async getDeposits() {
            this.loading = true;
            const { startDate, endDate } = this.selectedPeriod;
            try {
                this.periods = await this.queryHandler('getDeposits', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                    startDate,
                    endDate,
                    ...defaultMeta,
                });
            } catch (error) {
                this.notifyError(getAccountingErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
